/// <reference types="@emotion/react/types/css-prop" />
import {
  type LayoutModule,
  type ModuleProperties,
  useRenderedChildren,
} from '@backstage-components/base';
import {
  StackLayoutBase,
  type StackLayoutProps,
} from '@backstage-components/stack-layout';
import {Fragment, FC, useMemo} from 'react';
import type {SchemaType} from './CollectionListDefinition';

export type CollectionListProps = ICollectionListProps & SchemaType;
interface ICollectionListProps extends ModuleProperties {
  stackProps?: StackLayoutProps;
}

export type CollectionListComponentDefinition = LayoutModule<
  'CollectionList',
  CollectionListProps
>;

export const CollectionListComponent: FC<CollectionListComponentDefinition> = (
  definition
) => {
  const {slotRenderer: Component = () => <Fragment />} = definition;
  const renderedChildren = useRenderedChildren(Component, definition.slots);
  const {stackProps, useLayout} = definition.props;

  const collectionList = useMemo(() => {
    return renderedChildren;
  }, [renderedChildren]);

  return (
    <Fragment>
      {useLayout && stackProps ? (
        <StackLayoutBase
          {...definition}
          props={{...stackProps, styleAttr: definition.props.styleAttr}}
          component="Stacked"
        >
          {collectionList}
        </StackLayoutBase>
      ) : (
        <Fragment>{collectionList}</Fragment>
      )}
    </Fragment>
  );
};
