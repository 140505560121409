import {type ModuleNode} from '@backstage-components/base';
import {type FC} from 'react';
import {StackLayoutPreviewBase} from '@backstage-components/stack-layout';
import {type CollectionListProps} from './CollectionListComponent';

export const CollectionListPreviewComponent: FC<
  ModuleNode<'CollectionList', CollectionListProps>
> = (props) => {
  const {module, slotComponent: Slot} = props;
  const {id} = module;
  const {useLayout, stackProps} = module.props;

  return useLayout && stackProps ? (
    <StackLayoutPreviewBase
      id={id}
      {...props}
      module={{...module, component: 'Stacked', props: stackProps}}
    >
      <Slot slotName="items" />
    </StackLayoutPreviewBase>
  ) : (
    <Slot slotName="items" />
  );
};
