import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {
  CollectionListComponent,
  CollectionListComponentDefinition,
} from './CollectionListComponent';
import {ComponentDefinition} from './CollectionListDefinition';

type Kind = CollectionListComponentDefinition['component'];

type Props = CollectionListComponentDefinition['props'];

export const CollectionListModule: Module<Kind, Props> = {
  isContent(value): value is CollectionListComponentDefinition {
    return false;
  },
  isLayout(value): value is CollectionListComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (CollectionListModule.isContent(definition)) {
      return React.createElement(CollectionListComponent, definition);
    } else {
      return NextModule;
    }
  },
};
