export {CollectionListComponent} from './CollectionListComponent';
export type {CollectionListComponentDefinition} from './CollectionListComponent';
export {ComponentDefinition} from './CollectionListDefinition';
export type {SchemaType} from './CollectionListDefinition';
export {CollectionListPreviewComponent} from './CollectionListPreviewComponent';

import {Registry} from '@backstage-components/base';
import {CollectionListModule} from './CollectionListModule';
import {ComponentDefinition} from './CollectionListDefinition';
Registry.register(ComponentDefinition, CollectionListModule);
